<template>
  <div class="container">
    <div class="wrap">
      <div>
        <div>当前:
          <div :class="['current', currentPlayer===1?'hei':'hong']"></div>
        </div>
        <div>x: {{ holding.x }}</div>
        <div>y: {{ holding.y }}</div>
      </div>
      <ChineseChess ref="chess" v-on:setCurrentPlayer="setCurrentPlayer"></ChineseChess>

    </div>
  </div>
</template>

<script>
import ChineseChess from '../../components/ChineseChess.vue'

export default {
  components: {
    ChineseChess
  },
  data() {
    return {
        holding: {},
        currentPlayer : 0
    }
  },
  mounted() {
    this.holding = this.$refs.chess.holding
    this.currentPlayer = this.$refs.chess.currentPlayer
    document.title = '中国象棋'
  },
  methods : {
    setCurrentPlayer(player) {
      this.currentPlayer = player
    }
  }
}
</script>
<style>
.wrap {
  text-align: center;
  max-width: 750px;
}
</style>
