<template>
  <div class="page-body">
    <div class="page-section">
      <div class="page-section-title" style="width: 100%; font-size: 2em">
        <text>扫雷</text>
      </div>
      <div class="flex-wrapper" style="background-color: rgb(146 200 206);">
        <div style="width: 80%; padding-top: 1.2em">
          <van-slider
            v-model="mineCount"
            :step="5"
            block-size="100"
            block-color="#fff"
            style="display: flex"
            bar-height="0.2em"
            active-color="#ee0a24"
          >
            <template #button>
              <div class="custom-button">{{ mineCount }}</div>
            </template></van-slider
          >
        </div>
        <div style="width: 20%; text-align: center">
          <van-button class="mini-btn" type="success" @click="reloadArea">
            开始
          </van-button>
        </div>
      </div>
    </div>
    <div class="table">
      <div class="tbody">
        <div class="sweep-tr" v-for="item in this.area" :key="item">
          <div
            class="sweep-td-text"
            @click="clickTd(item)"
            v-for="(item, index) in item"
            :key="index"
          >
            <div :class="['cell', item.isShow ? '' : 'gray']">
              <div
                :class="['mine', item.isShow ? 'item_show' : 'item_hide']"
                v-if="item.isMine"
              >
                &nbsp;
              </div>
              <div
                :class="['number', item.isShow ? 'item_show' : 'item_hide']"
                v-else-if="item.showNumber"
              >
                <span
                  :class="[item.showNumber ? 'item_' + item.showNumber : '']"
                  >{{ item.showNumber }}</span
                >
              </div>
              <div class="empty" v-else>
                <span></span>
              </div>
            </div>
            <div v-if="area.length === 0">
              <div class="data-no">暂无数据</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mineCount: 10,
      mineList: [],
      table: [],
      area: [],
      row: 10,
      col: 10,
      openCount: 0,
      remainPos: [],
    };
  },
  computed: function () {},
  mounted() {
    document.title = '扫雷'
    console.log("Welcome to Mini Code");

    if (this.mineCount) {
      this.reloadArea();
    }
  },
  methods: {
    resetData() {
      this.mineList = [];
      this.area = [];
      this.table = [];
      this.openCount = 0;
      this.remainPos = [];
    },
    genArea(row, col) {
      let list = [];
      for (let x = 0; x < row; x++) {
        list[x] = [];
        for (let y = 0; y < col; y++) {
          let isMine = false;
          for (let jj = 0; jj < this.mineList.length; jj++) {
            if (this.mineList[jj] == x * 10 + (y + 1)) {
              let v = this.mineList[jj];
              isMine = true;
              break;
            }
          }
          let pos = {
            x: x,
            y: y,
            pos: x * 10 + (y + 1),
            isMine: isMine,
            showNumber: "",
            isShow: false,
            isTryOpen: false,
          };
          list[x][y] = pos;
          this.remainPos[this.remainPos.length] = pos.pos;
        }
      }
      this.table = list;
    },
    isMine(x, y, maxX, maxY) {
      if (x > -1 && x < maxX && y > -1 && y < maxY) {
        return this.table[x][y].isMine;
      }
    },
    isEmpty(x, y, maxX, maxY) {
      if (!(x > -1 && x < maxX && y > -1 && y < maxY)) {
        console.log("param error");
        return false;
      }
      if (this.isMine(x, y, maxX, maxY)) {
        console.log("isMine");
        return false;
      }
      if (this.table[x][y].showNumber != "") {
        console.log("isShowNumber");

        return false;
      }
      return true;
    },
    genMineNumber(row, col) {
      //绘制数字
      this.table.forEach((list, x) => {
        list.forEach((pos, y) => {
          // 获取周围8个是否地雷
          let c = 0;
          c += this.isMine(x - 1, y - 1, row, col) ? 1 : 0;
          c += this.isMine(x, y - 1, row, col) ? 1 : 0;
          c += this.isMine(x + 1, y - 1, row, col) ? 1 : 0;

          c += this.isMine(x - 1, y, row, col) ? 1 : 0;

          c += this.isMine(x + 1, y, row, col) ? 1 : 0;

          c += this.isMine(x - 1, y + 1, row, col) ? 1 : 0;
          c += this.isMine(x, y + 1, row, col) ? 1 : 0;
          c += this.isMine(x + 1, y + 1, row, col) ? 1 : 0;
          if (c) this.table[x][y].showNumber = c;
        });
      });
    },
    draw() {
      this.area = this.table;
    },
    clickEmpty(x, y, maxX, maxY) {
      if (x < 0 || y < 0 || x >= maxX || y >= maxY || x >= maxX || y >= maxY)
        return false;

      if (!this.isEmpty(x, y, maxX, maxY)) {
        console.log({ x, y, maxX, maxY });
        console.log("不是空白的");
        return false;
      }

      if (this.area[x][y].isTryOpen == true) {
        console.log("处理过了");
        return false;
      }
      this.area[x][y].isTryOpen = true;

      let list = [
        [x - 1, y - 1],
        [x, y - 1],
        [x + 1, y - 1],

        [x - 1, y],
        [x + 1, y],

        [x - 1, y + 1],
        [x, y + 1],
        [x + 1, y + 1],
      ];
      let area = this.area;
      let l = list.length;
      let rev = [];
      for (let i = 0; i < l; i++) {
        let x1 = list[i][0];
        let y1 = list[i][1];
        if (x1 < 0 || y1 < 0) continue;
        if (x1 >= maxX || y1 >= maxY) continue;
        try {
          if (area[x1][y1] && !area[x1][y1].isShow) {
            area[x1][y1].isShow = true;
            this.remainPos.splice(x1 * y1, 1);
            this.openCount++;

            console.log("显示:" + x + "," + y + " 的周围空白:" + x1 + "," + y1);
            console.log("area[" + x + "][" + y + "]");
          }
        } catch (e) {
          debugger;
        }

        if (this.isEmpty(x1, y1, maxX, maxY)) {
          rev[rev.length] = [x1, y1];
        }
      }

      rev.forEach((item, index) => {
        let x2 = item[0];
        let y2 = item[1];
        this.clickEmpty(x2, y2, maxX, maxY);
      });

      console.log("clickEmpty", x, y, maxX, maxY);
      this.area = area;
    },
    clickTd(item) {
      let type = item.type;
      let x = item.x;
      let y = item.y;
      let area = this.area;
      area[x][y].isShow = true;
      this.area = area;
      if (item.isMine) {
        this.endForMine();
      } else if (item.showNumber != "") {
        this._open(x, y);
      } else {
        this.clickEmpty(x, y, this.row, this.col);
        this._open(x, y);
      }
    },
    endForMine() {
      console.log("踩雷了");
      this.$toast("你踩雷了", {});
      this._end();
    },
    _open(x, y) {
      this.openCount++;
      this.remainPos.forEach((r, index) => {
        if (x * y == r) {
          this.remainPos.splice(index + 1, 1);
        }
      });
      console.log(this.remainPos);
      this._checkSuccess();
    },
    _checkSuccess() {
      let c = this.row * this.col - (this.openCount + this.mineCount);
      if (c <= 0) {
        // 达成
        this._success();
      }
    },
    _success() {
      this.$toast({
        title: "恭喜 , 你通关了",
        duration: 1000,
        success(res) {},
        fail(res) {
          console.log("showToast 调用失败", res);
        },
      });
      this._end();
    },
    _end() {
      let area = this.area;
      area.forEach((list, x) => {
        list.forEach((pos, y) => {
          area[x][y].isShow = true;
        });
      });
      this.area = area;
    },

    reloadArea() {
      this.resetData();
      if (this.mineCount) {
        // 埋雷
        let row = this.row;
        let col = this.col;
        let max = row * col;

        while (this.mineList.length < this.mineCount) {
          let r = Math.round(Math.random() * max);
          let isIn = false;
          for (let jj = 0; jj < this.mineList.length; jj++) {
            if (this.mineList[jj] === r) {
              isIn = true;
              break;
            }
          }

          if (!isIn) {
            this.mineList[this.mineList.length] = r;
          }
        }

        this.mineList.sort();
        // 重设区域
        this.genArea(row, col);
        // 设置对应的雷区周边的数值
        this.genMineNumber(row, col);
        // 绘制
        this.draw();
      }
    },
  },
};
</script>

<style>
.flex-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.flex-item {
  width: 200px;
  text-align: center;
  color: #fff;
}
.flex-item-slide {
  width: 400px;
  text-align: center;
  color: #fff;
}

.flex-item.hover-item {
  opacity: 0.6;
}

.flex-item-v {
  align-self: center;
  width: 300px;
  height: 160px;
  text-align: center;
  line-height: 2em;
  color: #fff;
}
.table {
  margin: auto;
  margin-top: 10px;
  width: 96%;
}
.sweep-tr {
  height: 2em;
  line-height: 2em;
  margin: 0;
}
.sweep-td-text {
  padding: 0;
  height: 2em;
  line-height: 2em;
  width: 2em;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  text-align: center;
  border: 1px solid rgb(177, 177, 177);
}
.data-no {
  text-align: center;
  line-height: 2em;
}
.mine {
  /* background-color: rgba(255, 131, 131, 0.37); */
  background-image: url("../../assets/lei.webp");
  background-repeat: no-repeat;
  background-size: contain;
}
.no-mine,
.mine {
  line-height: 2em;
  width: 2em;
  margin: 0;
  text-align: center;
  display: inline-table;
}
.item_hide {
  display: none;
  margin: 0px;
  padding: 0px;
}
.item_0 {
  color: coral;
}

.item_1 {
  color: rgb(38, 168, 185);
}

.item_2 {
  color: rgb(173, 10, 105);
}

.item_3 {
  color: rgb(75, 12, 75);
}

.item_4 {
  color: rgb(18, 155, 235);
}

.item_5 {
  color: rgb(94, 38, 38);
}

.item_6 {
  color: rgb(197, 19, 173);
}

.item_7 {
  color: rgb(143, 214, 27);
}

.item_8 {
  color: rgb(26, 70, 218);
}

.cell {
  display: block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
}

.gray {
  background-color: rgb(202, 202, 202);
}
.gray .r {
  display: none;
}
.custom-button {
  width: 2em;
  color: #fff;
  font-size: 1em;
  line-height: 1em;
  text-align: center;
  background-color: #ee0a24;
  border-radius: 0.5em;
}
.page-body {
  margin: 1em auto;
}
</style>
