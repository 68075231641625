<template>
  <div class="chess-number">
    <div v-for="item3 in [1,2,3,4,5,6,7,8,9]" class="black-number">
      {{ item3 }}
    </div>
  </div>
  <div class="chessList">

    <div class="chessPanel">

      <div v-for="item1 in list">
        <div class="chess-tr">

          <div v-for="item in item1" class="chess-td">
            <div
                :class="['box','chess', item.x===holding.x && item.y===holding.y ? ' holding' : '', item.chess.image , item.highlight ? 'highlight' : 'no-highlight']"
                @click="clickBox(item)"
                v-if="item.chess && item.chess.name"
                style="">
              &nbsp;
            </div>
            <div
                @click="clickBox(item)"
                :class="['box',item.highlight ? 'highlight' : 'no-highlight']"
                v-else>&nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chess-number">
    <div v-for="item3 in ['九','八','七','六','五','四','三','二','一']" class="red-number">
      {{ item3 }}
    </div>
  </div>
  <div class="logs">
    <div v-for="log in logs" class="log-td">
      {{ log }}
    </div>
  </div>
</template>
<script>

class ChessBase {
  name;
  image;
  box;
  nextRule;
  player;
  color;

  constructor(name, image, box, nextRule, player, color) {
    this.name = name;
    this.image = image;
    this.box = box;
    this.nextRule = nextRule;
    this.player = player;
    this.color = color;
  }

  nextPositionList(x, y, maxX, maxY, direction, upPlayer) {
    return []
  }
}

// 兵,卒子
class ChessBing extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
  }

  nextPositionList(x, y, maxX, maxY, direction, upPlayer) {
    return []
    let player = this.currentPlayer
    let myDirection = (upPlayer === this.currentPlayer && direction === 1) || (upPlayer !== this.currentPlayer && direction === 0) ? 1 : 0;
    let list = [];
    console.log(x, y, maxX, maxY, direction, upPlayer, myDirection)
    let line;
    let area;
    if (myDirection === 1) { // 向下进攻
      line = 4;
      area = [
        {x: x, y: y + 1}, // 向下走一步
        {x: x - 1, y: y}, // 向左走一步
        {x: x + 1, y: y}, // 向右走一步
      ]
    } else {
      line = 5;
      area = [
        {x: x, y: y - 1}, // 向上走一步
        {x: x - 1, y: y}, // 向左走一步
        {x: x + 1, y: y}, // 向右走一步
      ]
    }
    area.forEach(function (a) {
      if (a.x >= 0 && a.x < maxX && a.y >= 0 && a.y < maxY) {
        if (myDirection === 1 && a.y > line) { // 越界后才能左右
          list[list.length] = a
        } else if (myDirection === 0 && a.y < line) {
          list[list.length] = a
        }
      }
    });
    return list
  }
}

// 将帅
class ChessBoss extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
  }
}

class ChessChe extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
  }
}

class ChessMa extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
  }
}

class ChessPao extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
  }
}

class ChessShi extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
  }
}

class ChessXiang extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
  }
}

// 兵,卒子
class ChessHongBing extends ChessBing {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
    this.player = 0;
    this.name = "兵";
    this.color = "红";
    this.image = "hongbing";
  }
}

// 将帅
class ChessHongBoss extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
    this.player = 0;
    this.name = "帅";
    this.color = "红";
    this.image = "hongboss";
  }
}

class ChessHongChe extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
    this.player = 0;
    this.name = "车";
    this.color = "红";
    this.image = "hongche";
  }
}

class ChessHongMa extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
    this.player = 0;
    this.name = "马";
    this.color = "红";
    this.image = "hongma";
  }
}

class ChessHongPao extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
    this.player = 0;
    this.name = "炮";
    this.color = "红";
    this.image = "hongpao";
  }
}

class ChessHongShi extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
    this.player = 0;
    this.name = "仕";
    this.color = "红";
    this.image = "hongshi";
  }
}

class ChessHongXiang extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
    this.player = 0;
    this.name = "相";
    this.color = "红";
    this.image = "hongxiang";
  }
}

// 兵,卒子
class ChessHeiBing extends ChessBing {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
    this.player = 1;
    this.name = "卒";
    this.color = "黑";
    this.image = "heibing";
  }
}

// 将帅
class ChessHeiBoss extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
    this.player = 1;
    this.name = "将";
    this.color = "黑";
    this.image = "heiboss";
  }
}

class ChessHeiChe extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
    this.player = 1;
    this.name = "車";
    this.color = "黑";
    this.image = "heiche";
  }
}

class ChessHeiMa extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
    this.player = 1;
    this.name = "馬";
    this.color = "黑";
    this.image = "heima";
  }
}

class ChessHeiPao extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
    this.player = 1;
    this.name = "砲";
    this.color = "黑";
    this.image = "heipao";
  }
}

class ChessHeiShi extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
    this.player = 1;
    this.name = "士";
    this.color = "黑";
    this.image = "heishi";
  }
}

class ChessHeiXiang extends ChessBase {
  constructor(name, image, box, nextRule, player, color) {
    super(name, image, box, nextRule, player, color);
    this.player = 1;
    this.name = "像";
    this.color = "黑";
    this.image = "heixiang";
  }
}

export default {
  data() {
    return {
      direction: 1, // 棋盘方向(0:上黑下红; 1: 上红下黑)
      list: [], // 所有格子
      killer: {
        hong: [],
        hei: [],
      },
      allowPositions: [], // 当前holding的棋子能去的地方
      gameOverName: ['帅', '将'], // 结束的棋子
      signPlayer: 0, // 登录用户
      currentPlayer: 0, // 当前用户的棋子(0:红色,1:黑色)
      currentColor: 'hong', // 当前用户的颜色(hong:红,hei:黑)
      holding: {x: -1, y: -1}, // 当前持有的棋子的坐标
      logs: [],
      maxX: 9, // 棋盘边界
      maxY: 10, // 棋盘边界
      initData: [], // 存放初始化布局的棋子
      status: {
        winnerPlayer: -1
      }
    }
  },
  computed: function () {
  },
  mounted() {
    this.ready()
  },
  methods: {
    reset() {
      this.killer = {hong: [], hei: []}
      this.currentPlayer = 0;
      this.list = [];
      this.direction = 0;
      this.status = {winnerPlayer: -1}
      this.initData = [
        {x: 0, y: 0, player: 1, chess: new ChessHeiChe()},
        {x: 1, y: 0, player: 1, chess: new ChessHeiMa()},
        {x: 2, y: 0, player: 1, chess: new ChessHeiXiang()},
        {x: 3, y: 0, player: 1, chess: new ChessHeiShi()},
        {x: 4, y: 0, player: 1, chess: new ChessHeiBoss()},
        {x: 5, y: 0, player: 1, chess: new ChessHeiShi()},
        {x: 6, y: 0, player: 1, chess: new ChessHeiXiang()},
        {x: 7, y: 0, player: 1, chess: new ChessHeiMa()},
        {x: 8, y: 0, player: 1, chess: new ChessHeiChe()},

        {x: 1, y: 2, player: 1, chess: new ChessHeiPao()},
        {x: 7, y: 2, player: 1, chess: new ChessHeiPao()},

        {x: 0, y: 3, player: 1, chess: new ChessHeiBing()},
        {x: 2, y: 3, player: 1, chess: new ChessHeiBing()},
        {x: 4, y: 3, player: 1, chess: new ChessHeiBing()},
        {x: 6, y: 3, player: 1, chess: new ChessHeiBing()},
        {x: 8, y: 3, player: 1, chess: new ChessHeiBing()},

        {x: 0, y: 6, player: 0, chess: new ChessHongBing()},
        {x: 2, y: 6, player: 0, chess: new ChessHongBing()},
        {x: 4, y: 6, player: 0, chess: new ChessHongBing()},
        {x: 6, y: 6, player: 0, chess: new ChessHongBing()},
        {x: 8, y: 6, player: 0, chess: new ChessHongBing()},

        {x: 1, y: 7, player: 0, chess: new ChessHongPao()},
        {x: 7, y: 7, player: 0, chess: new ChessHongPao()},

        {x: 0, y: 9, player: 0, chess: new ChessHongChe()},
        {x: 1, y: 9, player: 0, chess: new ChessHongMa()},
        {x: 2, y: 9, player: 0, chess: new ChessHongXiang()},
        {x: 3, y: 9, player: 0, chess: new ChessHongShi()},
        {x: 4, y: 9, player: 0, chess: new ChessHongBoss()},
        {x: 5, y: 9, player: 0, chess: new ChessHongShi()},
        {x: 6, y: 9, player: 0, chess: new ChessHongXiang()},
        {x: 7, y: 9, player: 0, chess: new ChessHongMa()},
        {x: 8, y: 9, player: 0, chess: new ChessHongChe()},
      ];
    },
    ready() {
      this.reset()
      let l = this.initData.length;
      let list = [];
      let initData = this.initData;
      if (this.currentPlayer) {
      }
      for (let j = 0; j < this.maxY; j++) {
        list[j] = [];
        for (let i = 0; i < this.maxX; i++) {
          let chess, active, initPlayer;
          for (let k = 0; k < l; k++) {
            if (initData[k].x === i && initData[k].y === j) {
              chess = initData[k].chess;
              initPlayer = initData[k].player;
              if (initData.currentPlayer === initData[k].player) {
                active = true;
              } else {
                active = false;
              }
              break;
            }
          }
          list[j][i] = {x: i, y: j, chess: chess, player: initPlayer, active: active, highlight: false};
        }
      }
      this.list = list
      this.initData = {}
    },
    _move(from, to) {
      console.log(from, " => ", to)
      let chess = from.chess
      let to_chess = to.chess
      let from_x = from.x
      let form_y = from.y
      let from_player = from.player
      let name = chess.name + this.tranNumber(from.player, from_x)
      let _y = Math.abs(from.y - to.y)
      if (_y) _y--;
      if (from.player === this.signPlayer) {
        // 自己
        if (from.y > to.y) {
          name += '进'

          name += this.tranY(from.player, _y)
        } else if (from.y === to.y) {
          name += '平'
          name += this.tranY(from.player, this.maxX - to.x - 1)
        } else {
          name += '退'
          name += this.tranY(from.player, _y)
        }
      } else {
        // 别人
        if (from.y > to.y) {
          name += '退'
          name += this.tranY(from.player, _y)
        } else if (from.y === to.y) {
          name += '平'
          name += this.tranY(from.player, to.x)
        } else {
          name += '进'
          name += this.tranY(from.player, _y)
        }
      }
      this.logs[this.logs.length] = name
      console.log(this.logs)
    },
    _kill(item, killer) {
      console.log(item, '吃了:', killer)
      let lostPlayer;
      this.gameOverName.forEach((name) => {
        if (name === killer.name) {
          lostPlayer = killer.player
        }
      })
      if (undefined !== lostPlayer) {
        this._win(lostPlayer)
      }
    },
    _win(lostPlayer) {
      let user = ''
      if (lostPlayer) {
        user = '红方'
      } else {
        user = '黑方'
      }
      this.status.winnerPlayer = lostPlayer ? 0 : 1
      this.$toast(user + " 获胜");
    },
    _currentPlayer(player) {
      this.currentPlayer = player
      this.currentColor = player === 1 ? 'hei' : 'hong';
      this.$emit('setCurrentPlayer', this.currentPlayer)
    },
    tranNumber(player, number) {
      let data = {
        0: {
          0: '九',
          1: '八',
          2: '七',
          3: '六',
          4: '五',
          5: '四',
          6: '三',
          7: '二',
          8: '一',
        },
        1: {
          0: '1',
          1: '2',
          2: '3',
          3: '4',
          4: '5',
          5: '6',
          6: '7',
          7: '8',
          8: '9',
        },
      };
      if (undefined !== data[player] && undefined !== data[player][number])
        return data[player][number]
    },
    tranY(player, number) {
      let data = {
        0: {
          0: '一',
          1: '二',
          2: '三',
          3: '四',
          4: '五',
          5: '六',
          6: '七',
          7: '八',
          8: '九',
        },
        1: {
          0: '1',
          1: '2',
          2: '3',
          3: '4',
          4: '5',
          5: '6',
          6: '7',
          7: '8',
          8: '9',
        },
      };
      if (undefined !== data[player] && undefined !== data[player][number])
        return data[player][number]
    },
    clickBox(item) {
      if (this.status.winnerPlayer === -1) {
        let x = item.x;
        let y = item.y;
        let active = item.active;
        let list = this.list;
        let targetItem = list[y][x].chess;
        let holding = this.holding.x !== -1 ? list[this.holding.y][this.holding.x] : undefined;
        console.log("上一步点击了:", this.holding);
        console.log("此刻点击了", list[y][x]);
        if (x === this.holding.x && y === this.holding.y) {
          // 第二次点同一个
          // 取消选中
          console.log("取消选中:", this.holding);
          this.holding = {x: -1, y: -1};
        } else if (this.holding.x !== -1 && this.holding.y !== -1) {
          if (undefined === list[this.holding.y][this.holding.x].chess) {
            // 之前选中的是空
            console.log("之前选中的是空:", this.holding);
          } else {
            if (
                undefined !== list[y][x].chess &&
                undefined !== list[this.holding.y][this.holding.x].chess &&
                list[this.holding.y][this.holding.x].chess.player ===
                list[y][x].chess.player
            ) {
              console.log("兄弟 , 自己人啊 , 换选中他");
              this.holding = {x: x, y: y}
            } else {
              // 吃了对方的子
              // 点了另一个位置
              console.log("结果:", this.holding);
              let old = list[this.holding.y][this.holding.x];
              let allowNext = old.chess.nextPositionList(this.holding.x, this.holding.y, this.maxX, this.maxY, this.direction, this.currentPlayer);
              let allow = false;
              if (allowNext && allowNext.length > 0) {
                console.log("限制可以去的位置:", allowNext);
                allowNext.forEach((o) => {
                  if (o.x === x && o.y === y) {
                    allow = true
                  }
                })
              } else {
                console.log('无限制')
                allow = true
              }
              if (allow) {
                if (undefined === old.chess) {
                  // 移到空地
                  console.log("移到空地");
                } else {
                  console.log("吃对手的子");

                  // 移过来的
                  // 清空holding
                  // 替换current , 如果current不是空的 , 并且不是自己的 则kill , 否则不动
                  if (undefined !== targetItem) {
                    let killer = this.killer;
                    if (old.player) {
                      killer.hong[killer.hong.length] = targetItem;
                    } else {
                      killer.hei[killer.hong.length] = targetItem;
                    }
                    this._kill(item, targetItem)

                    this.killer = killer
                  }
                }
                this._move(holding, list[y][x])
                this._currentPlayer(this.currentPlayer === 1 ? 0 : 1)
                list[y][x] = {
                  x: x,
                  y: y,
                  chess: list[this.holding.y][this.holding.x].chess,
                };
                list[this.holding.y][this.holding.x] = {
                  x: -1,
                  y: -1,
                };
                this.holding = {x: -1, y: -1};
                this.list = list;
              } else {
                console.log('不能走到这里');
              }
            }
          }
        } else if (undefined !== list[y][x].chess) {
          // 没holding , 点了棋子
          console.log("没holding , 同时点了棋子");

          let list = this.list;
          let chessPlayer = list[y][x].chess.player;
          if (chessPlayer === this.currentPlayer) {
            // Todo: 此刻要指定是否可以被点击
            let oldPosition = this.allowPositions
            let allow = list[y][x].chess.nextPositionList(x, y, this.maxX, this.maxY, this.direction, this.currentPlayer)
            if (undefined !== allow) {
              this.allowPositions = allow
              oldPosition.forEach((p) => {
                this.list[p.y][p.x].highlight = true
              })

              allow.forEach((p) => {
                this.list[p.y][p.x].active = true
              })
            }

            this.holding = {x: x, y: y};

            console.log("选中:", {
              x: this.holding.x,
              y: this.holding.y,
            });

          } else {
            console.log("大哥,这是敌军的棋子,你选不了");
          }
        }
      } else {
        console.log('决出胜负了啊,大哥');
      }
    }
  }
}
</script>
<style>
.chess-number {
  width: 100%;
  height: 2em;
  margin-top: 0.5em;
  padding-top: 0.5em;
}

.chess-number .black-number, .chess-number .red-number {
  display: inline-block;
  width: 2.4em;
  height: 4em;
  line-height: 2.5em;
  margin: 0.1em;
}


.chessList {
  width: 100%;
  height: 26em;
  background-image: url("../assets/chess/bg/default.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  margin-top: 0.5em;
  padding-top: 0.5em;
  margin-bottom: 0em;
  padding-bottom: 0em;
}

.chessPanel {
}

.chess-tr {
  height: 1em;
  line-height: 1em;
  margin-bottom: 1.5em;
  margin-top: 0.1em
}

.chess-td {
  display: inline-block;
  width: 2.4em;
  height: 4em;
  line-height: 2.5em;
  margin: 0.1em;
}

.box {
  width: 2.4em;
  vertical-align: center;
  background-size: 2.4em;
  background-repeat: no-repeat;
}

.holding {
  border: 1px solid #dd0202
}

.hei {
  background-image: url("../assets/chess/chess/heiboss.png");
}

.hong {
  background-image: url("../assets/chess/chess/hongboss.png");
}

.heibing {
  background-image: url("../assets/chess/chess/heibing.png");
}

.heiboss {
  background-image: url("../assets/chess/chess/heiboss.png");
}

.heiche {
  background-image: url("../assets/chess/chess/heiche.png");
}

.heima {
  background-image: url("../assets/chess/chess/heima.png");
}

.heipao {
  background-image: url("../assets/chess/chess/heipao.png");
}

.heishi {
  background-image: url("../assets/chess/chess/heishi.png");
}

.heixiang {
  background-image: url("../assets/chess/chess/heixiang.png");
}

.hongbing {
  background-image: url("../assets/chess/chess/hongbing.png");
}

.hongboss {
  background-image: url("../assets/chess/chess/hongboss.png");
}

.hongche {
  background-image: url("../assets/chess/chess/hongche.png");
}

.hongma {
  background-image: url("../assets/chess/chess/hongma.png");
}

.hongpao {
  background-image: url("../assets/chess/chess/hongpao.png");
}

.hongshi {
  background-image: url("../assets/chess/chess/hongshi.png");
}

.hongxiang {
  background-image: url("../assets/chess/chess/hongxiang.png");
}

.highlight {
  background-color: #f0f0f0;
}

.no-highlight {
  background-color: none;
}

.current {
  width: 2.4em;
  height: 2.4em;
  line-height: 2.4em;
  background-size: 2.4em 2.4em;
  background-repeat: no-repeat;
}

.logs {
  text-align: left;
  padding: 1em;
}

.log-td {
  display: inline-block;
  width: 50%
}
</style>