<template>
  <div> hello </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  computed: function () {},
  mounted() {

  },
  methods: {
    ready() {
      
    }
  }
}
</script>
<style>
</style>